/**
 * Site footer
 */
.site-footer {
  padding: 3rem 1rem;
  text-align: left;
  position: relative;
  z-index: 100;
  min-height: calc( 100vh - 6rem );
  display: flex;
  align-items: center;
  color: white;
  font-family: $ff-sans-serif-display;
  font-size: 1.15rem;

  a:link,
  a:visited {
    color: $blue;
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      display: inline-block;
      margin: 0 5px;
    }
  }

  .copyright {
    font-size: .875rem;
    margin-top: 2rem;
  }
}

.color-yellow {
  .site-footer {
    color: $black;

    a:link,
    a:visited {
      color: $black;
    }
  }
}
