@mixin header-over-background {
  position: relative;
  color: #fff;
  text-shadow: 0 1px 1px #000;
  margin: 0 0 0.5em;
  font-size: 2.25rem;
  line-height: 1.25;

  @media only screen and (max-width: 590px) {
    font-size: 1.75rem;
  }
}

@mixin pixelated-bg {
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpEMjlERTZGOTVFMjYxMUU1QjY4MUZBRjg2OTFGNjM1NSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpEMjlERTZGQTVFMjYxMUU1QjY4MUZBRjg2OTFGNjM1NSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkQyOURFNkY3NUUyNjExRTVCNjgxRkFGODY5MUY2MzU1IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkQyOURFNkY4NUUyNjExRTVCNjgxRkFGODY5MUY2MzU1Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+68HIcQAAAB5JREFUeNpiEODna/j//z8DCIMJJiamBjgHJgAQYACI2xOooYMk7wAAAABJRU5ErkJggg==) repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 120;
}

@mixin background-image {
  position: relative;
  z-index: 0;
  object-fit: cover;
  object-position: center center;
  opacity: 0.75;
  width: auto;
  height: 100%;
}

hr {
  border-color: $black;
}

.bg-body {
  display: flex;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: -1;
  background-image: url('/assets/bg-lines.svg');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}

.home-title {
  font-family: $header-font-family;
  font-weight: 900;
  padding: 0 0 0 0.5rem;
  font-size: 1.5rem;
  line-height: 1;
  margin: 0;
  text-align: left;
  letter-spacing: 0;
  text-transform: uppercase;
  
  @media screen and (min-width: 592px) {
    font-size: 3rem;
  }

  @media screen and (min-width: 772px) {
    font-size: 4rem;
  }

  .material-icons {
    font-size: 1rem;

    @media screen and (min-width: 592px) {
      font-size: 2rem;
    }
  }
}

[data-color="black"] {
  .serif,
  .material-icons {
    color: $blue;
  }

  .text {
    color: $yellow;
  }
}

[data-color="red"] {
  .serif,
  .material-icons {
    color: $black;
  }
    
  .text {
    color: $black;
  }
}

.home-container {
  min-height: calc( 90vh - 6rem );
  width: calc( 100% - 2rem );
  padding: 3rem 1rem;
  position: relative;

  &.about-container {
    display: flex;
    align-items: baselines;
    justify-content: center;
    flex-direction: column;
  }

  .serif {
    font-family: $ff-serif-display;
    font-size: 1rem;
    color: $blue;
    padding-left: 2rem;
    margin-bottom: 0;

    @media screen and (min-width: 592px) {
      font-size: 2rem;
      padding-left: 3.5rem;
    }
  }

  a:link,
  a:visited {
    color: #97E8ED;
    font-weight: bold;
  }

  &.post-container {
    border-bottom: none;
    padding-bottom: 0;
  }

  h2 {
    text-align: left;
    color: #fff;
    font-family: $ff-serif-display;
  }
}

.post {
  flex-grow: 1;
  position: relative;
  z-index: 1;

  img {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
}

.lede-image,
.thumbnail-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 0;
  position: absolute;
  margin-bottom: 0;
  opacity: .75;

  div {
    display: block;
    overflow: hidden;
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    margin: 0px;
  }

  img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  &:before {
    @include pixelated-bg();
  }
}

.post-wrapper {
  position: relative;
  z-index: 5;
  padding-top: 2em;
  padding-bottom: 1em;
}

.wp-caption-text {
  font-size: .875em;
  margin-top: .5em;
  color: #888;
}

/**
 * Page content
 */
.page-content {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 100%;
  grid-auto-rows: 1fr;
  grid-auto-flow: dense;

  @media screen and (min-width: $on-medium) {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-auto-rows: 400px;
  }

  li {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: left;
    z-index: 10;
    background-color: $black;

    &:active {
      transform: scale(0.98);
    }

    .post-content {
      padding: 50px 25px 10px;
    }

    .post-content {
      h3 {
        position: relative;
        font-size: 1.75rem;
        line-height: 1.2;
        margin-top: 0;
        margin-bottom: 0.5rem;
        color: #fff;
        text-shadow: 0 1px 0 $black;
        text-wrap: balance;
      }

      .post-meta {
        color: #fff;
        text-shadow: 0 1px 0 $black;
        position: relative;
        z-index: 1080;
        font-family: $ff-sans-serif-display;
        font-weight: 600;
      }
    }
  }
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

/**
 * Posts
 */
.post-header {
  color: #fff;
  display: grid;
  grid-row: 1;
  overflow: hidden;
  position: relative;
  font-size: 1.2rem;
  align-content: start;
  height: auto;

  .wrapper {
    width: calc(100% - 30px);
    max-width: calc(100% - 30px);
    display: grid;
    padding: 3rem 15px 1rem;
    grid-gap: 1rem;
    grid-row: 1;
    position: relative;
    min-height: 33.33333%;
    grid-column: 1 / -1;
    align-content: end;

    @media screen and (min-width: $on-medium) {
      @include relative-font-size(2);
      width: calc(100% - 60px);
      max-width: calc(100% - 60px);
      padding-top: 3rem 30px 1rem;
    }

    @media screen and (min-width: $on-large) {
      grid-template-columns: 1fr 1fr;
      padding-top: 4rem;
      align-items: center;
    }
  }

  .post-meta {
    position: relative;
    color: $black;
    font-size: 1rem;
    margin: 0 0 0.5rem;
    font-family: $ff-sans-serif-display;
    font-weight: 700;
  }
}

.post-title,
.post-content h1 {
  @include relative-font-size(1.75);
  letter-spacing: -1px;
  line-height: 1.15;
  margin-bottom: 0.5rem;
  color: $black;
  text-wrap: balance;

  @media screen and (min-width: $on-large) {
    @include relative-font-size(2.625);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h1,
  h2,
  h3 {
    margin-top: $spacing-unit * 2
  }

  h4,
  h5,
  h6 {
    margin-top: $spacing-unit
  }

  h2 {
    @include relative-font-size(1.75);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(2);
    }
  }

  /*h3 {
    @include relative-font-size(1.375);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(1.625);
    }
  }*/

  h4 {
    @include relative-font-size(1.25);
  }

  h5 {
    @include relative-font-size(1.125);
  }

  h6 {
    @include relative-font-size(1.0625);
  }
}

.post-link {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
  }
}

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {  
  height: auto;
} 
@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }  
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }  
}
