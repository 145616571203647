/**
 * Site header
 */
.site-header {
  min-height: $spacing-unit * 1.865;
  line-height: $base-line-height * $base-font-size * 2.25;
  text-align: center;
  position: relative;
  z-index: 100;

  // Positioning context for the mobile navigation icon
  position: relative;
  z-index: 10;
}

.site-title {
  font-family: $header-font-family;
  font-weight: 900;
  text-transform: uppercase;
  padding: 0;
  font-size: 1.75em;
  line-height: 1;
  margin: 12px auto 7px;
  text-align: center;

  a {
    color: $yellow;
    text-decoration: none;

    &:hover {
      color: $link-hover-color;
      text-decoration: none;
    }
  }

  .material-icons {
    color: $blue;
  }
}

.site-nav {
  position: absolute;
  top: 9px;
  right: $spacing-unit / 2;
  border: 1px solid $border-color-01;
  border-radius: 5px;
  text-align: right;

  .nav-trigger {
    display: none;
  }

  .menu-icon {
    float: right;
    width: 36px;
    height: 26px;
    line-height: 0;
    padding-top: 10px;
    text-align: center;

    >svg path {
      fill: $border-color-03;
    }
  }

  label[for="nav-trigger"] {
    display: block;
    float: right;
    width: 36px;
    height: 36px;
    z-index: 2;
    cursor: pointer;
  }

  input~.trigger {
    clear: both;
    display: none;
  }

  input:checked~.trigger {
    display: block;
    padding-bottom: 5px;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;
    display: block;
    padding: 5px 10px;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 0;
    }

    margin-left: 20px;
  }

  @media screen and (min-width: $on-medium) {
    position: static;
    float: right;
    border: none;

    label[for="nav-trigger"] {
      display: none;
    }

    .menu-icon {
      display: none;
    }

    input~.trigger {
      display: block;
    }

    .page-link {
      display: inline;
      padding: 0;

      &:not(:last-child) {
        margin-right: 20px;
      }

      margin-left: auto;
    }
  }
}
